import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import DetailCard from './DetailCard';

import useInvitation from '@/hooks/useInvitation';

import { IMG_MAN, IMG_GIRL } from '@/constants/assets';
import {
  BOY_NAME,
  BOY_PARENT_NAME,
  GIRL_NAME,
  GIRL_PARENT_NAME,
  IG_BOY,
  IG_GIRL,
  IS_BOY_FIRST,
} from '@/constants';
import { ENABLE_MUSLIM_INVITATION } from '@/constants/features-flag';
import txt from './locales';
import useLang from '@hooks/useLang';

const HEIGHT_OVERFLOW = '50px';
const COMMON_OPTIONS = {
  fullNameProps: { fontSize: '2xl', padding: '8px 0 4px 0' },
  instagramButton: { onlyIcon: true, backgroundColor: '#B89661', size: 'sm' },
};

function CoupleInfo({ ...rest }) {
  const isInvitation = useInvitation();
  const lang = useLang();
  return (
    <Box>
      <Box {...rest}>
        <WithAnimation>
          {ENABLE_MUSLIM_INVITATION ? (
          <Text align="center" fontSize="sm" color="mainColorText" padding="24px 16px">
            {isInvitation
              ? <>{txt.openingMuslimInvitation[lang]}</>
              : <>{txt.openingMuslimAnnouncement[lang]}</>
            }
          </Text>)
          :
          (<Text align="center" fontSize="sm" color="mainColorText" padding="24px 16px">
          {isInvitation
            ? <>{txt.openingGeneralInvitation[lang]}</>
            : <>{txt.openingGeneralAnnouncement[lang]}</>
          }
        </Text>)
        }
        </WithAnimation>
        <Box height={HEIGHT_OVERFLOW} />
        {/* BOX with Invert Colors */}
        {IS_BOY_FIRST ? <Box bgColor="mainColor">
          <Box padding="0px 32px" transform={`translateY(-${HEIGHT_OVERFLOW})`}>
            <WithAnimation>
              <DetailCard
                imgSrc={IMG_MAN}
                fullName={BOY_NAME}
                fullNameProps={COMMON_OPTIONS.fullNameProps}
                parentInfo={BOY_PARENT_NAME[lang]}
                instagramId={IG_BOY}
                instagramIdProps={COMMON_OPTIONS.instagramButton}
              />
            </WithAnimation>
          </Box>
          <Box height={`calc(${HEIGHT_OVERFLOW} - 16px)`} />
        </Box> : ''}
        {IS_BOY_FIRST ? '' : <Box bgColor="mainColor">
          <Box padding="0px 32px" transform={`translateY(-${HEIGHT_OVERFLOW})`}>
            <WithAnimation>
              <DetailCard
                imgSrc={IMG_GIRL}
                fullName={GIRL_NAME}
                fullNameProps={COMMON_OPTIONS.fullNameProps}
                parentInfo={GIRL_PARENT_NAME[lang]}
                instagramId={IG_GIRL}
                instagramIdProps={COMMON_OPTIONS.instagramButton}
              />
            </WithAnimation>
          </Box>
          <Box height={`calc(${HEIGHT_OVERFLOW} - 16px)`} />
        </Box> }

        {IS_BOY_FIRST ? <Box>
          <Box padding="0px 32px" transform={`translateY(-${HEIGHT_OVERFLOW})`}>
            <WithAnimation>
              <DetailCard
                fullName={GIRL_NAME}
                fullNameProps={{ ...COMMON_OPTIONS.fullNameProps, color: 'mainColorText' }}
                parentInfo={GIRL_PARENT_NAME[lang]}
                parentInfoProps={{ color: 'mainColorText' }}
                instagramId={IG_GIRL}
                imgSrc={IMG_GIRL}
                instagramIdProps={COMMON_OPTIONS.instagramButton}
              />
            </WithAnimation>
          </Box>
        </Box> : ''}
        {IS_BOY_FIRST ? '' : <Box>
          <Box padding="0px 32px" transform={`translateY(-${HEIGHT_OVERFLOW})`}>
            <WithAnimation>
              <DetailCard
                fullName={BOY_NAME}
                fullNameProps={{ ...COMMON_OPTIONS.fullNameProps, color: 'mainColorText' }}
                parentInfo={BOY_PARENT_NAME[lang]}
                parentInfoProps={{ color: 'mainColorText' }}
                instagramId={IG_BOY}
                imgSrc={IMG_MAN}
                instagramIdProps={COMMON_OPTIONS.instagramButton}
              />
            </WithAnimation>
          </Box>
        </Box> }
      </Box>
    </Box>
  );
}

export default React.memo(CoupleInfo);
