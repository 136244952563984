import React from 'react';
import { bool, object } from 'prop-types';

import { styWave } from './styles';

function Wave({ inverse, style, v2, isWhiteColor }) {
  return <div css={styWave(inverse, v2, isWhiteColor)} style={style} />;
}

Wave.propTypes = {
  isWhiteColor: bool,
  inverse: bool,
  style: object,
  v2: bool,
};

Wave.defaultProps = {
  inverse: false,
  isWhiteColor: false,
  style: {},
  v2: false,
};

export default React.memo(Wave);
