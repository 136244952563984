import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { BsFillEnvelopeOpenFill } from 'react-icons/bs';
import { Button, Center } from '@chakra-ui/react';
import txt from './locales';
import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

/**
 * function to render button scroll down
 * @returns {JSX.Element}
 */
function ScrollToDown({ loading, onClick, withIcon, style }) {
  const lang = useLang();
  const isInvitation = useInvitation();
  return (
    <Center style={style}>
      <Button
        borderRadius="16px"
        leftIcon={withIcon ? <BsFillEnvelopeOpenFill /> : null}
        colorScheme="blackAlpha"
        size="sm"
        isLoading={loading}
        onClick={onClick}
        className="animation-heart"
        fontWeight="normal"
        background="rgb(123 95 83 / 70%)"
      >
        {isInvitation ? txt.open[lang] : txt.openAnn[lang]}
      </Button>
    </Center>
  );
}

ScrollToDown.propTypes = {
  loading: bool.isRequired,
  text: string,
  style: object,
  withIcon: bool,
  onClick: func.isRequired,
};

ScrollToDown.defaultProps = {
  withIcon: true,
  style: {},
};

export default React.memo(ScrollToDown);
