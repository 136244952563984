import React from 'react';
import { string } from 'prop-types';
import { FaGlassCheers } from 'react-icons/fa';
import { Box, Center, Text, Heading } from '@chakra-ui/react';

import CountDown from '@components/Common/CountDown';
import CountDown2 from '@components/Common/CountDown/main';
import WithAnimation from '@components/Common/WithAnimation';
import IconWeddingRing from '@components/Common/Icons/WeddingRing';
import WeddingLocation from './WeddingLocation';
import WeddingLocation2 from './WeddingLocation2';
import WeddingDetail from './WeddingDetail';

import useInvitation from '@/hooks/useInvitation';
import useShiftTime from '@/hooks/useShiftTime';

import { BG_WEDDING_DETAIL, BG_PATTERN } from '@/constants/assets';
import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_TIME,
} from '@/constants';
import txt from './locales';
import useLang from '@hooks/useLang';

/**
 * function to render WeddingSectionV4 component
 * @returns {JSX.Element}
 */
function WeddingSectionV4({ color, ...rest }) {
  const isInvitation = useInvitation();
  const time = useShiftTime();
  const lang = useLang();

  return (
    <Box backgroundImage={`url(${BG_PATTERN})`} bgSize="cover" bgPos="center" padding="32px 16px" {...rest}>
      <Box>
        <Box boxShadow="2xl" backgroundColor="mainColor" borderRadius="2xl" padding="36px 32px">
          <WithAnimation>
            <Center>
              <Heading fontSize="3xl" color="white" fontWeight="bold" align="center">
                {txt.title[lang]}
              </Heading>
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Text color={color} align="center">
              {txt.description[lang]}
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Center margin="12px 0">
              {time ==="Tasyakuran"
                ? <CountDown2 />
                : <CountDown />
              }
            </Center>
          </WithAnimation>
          <Box
            backgroundImage={`url(${BG_WEDDING_DETAIL})`}
            backgroundSize="cover"
            backgroundPosition="center"
            borderRadius="16px"
            padding="16px"
            marginTop="16px"
            boxShadow="inset 0 0 0 2000px rgb(73 57 49 / 60%)"
          >
            {/* Announcement */}
            {!isInvitation && (
              <WithAnimation>
                <Box>
                  <WeddingDetail
                    color={color}
                    title="Akad Nikah"
                    titleProps={{ color }}
                    Icon={<IconWeddingRing height="100px" width="100px" color={color} />}
                    subTitle={
                      <>
                        <Text margin="8px 0 8px">{WEDDING_AKAD_TIME}</Text>
                        <Text marginBottom="8px">{WEDDING_AKAD_FULLDATE}</Text>
                      </>
                    }
                  />
                </Box>
              </WithAnimation>
            )}
            
            {/* Inv General */}
            {isInvitation && time ==="Shift" && (
              <>
                <WithAnimation>
                  <Box>
                    <WeddingDetail
                      color={color}
                      title="Akad Nikah"
                      titleProps={{ color }}
                      Icon={<IconWeddingRing height="100px" width="100px" color={color} />}
                      subTitle={
                        <>
                          <span>{WEDDING_AKAD_TIME}</span>
                          <br />
                          <span>{WEDDING_AKAD_FULLDATE}</span>
                        </>
                      }
                    />
                  </Box>
                </WithAnimation>
                <WithAnimation>
                  <WeddingDetail
                    color={color}
                    titleProps={{ color }}
                    title="Resepsi Pernikahan"
                    Icon={<FaGlassCheers size="80px" color={color} marginBottom="4px" />}
                    subTitle={
                      <>
                        <span>{WEDDING_RESEPSI_TIME}</span>
                        <br />
                        <span>{WEDDING_AKAD_FULLDATE}</span>
                      </>
                    }
                  />
                </WithAnimation>
                <Box>
                  <WithAnimation>
                    <Text
                      align="center"
                      fontSize="xl"
                      fontFamily="fantasy"
                      fontWeight="bold"
                      color="white"
                    >
                      <span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_NAME }} />
                    </Text>
                  </WithAnimation>
                  <WithAnimation>
                    <Text fontSize="sm" align="center" color={color}>
                      {WEDDING_RESEPSI_LOC_ROAD}
                    </Text>
                  </WithAnimation>
                  <WithAnimation>
                    <WeddingLocation
                      color={color}
                      withIcon={false}
                      withLocation={false}
                      withRoad={false}
                    />
                  </WithAnimation>
                </Box>
              </>
            )}
            
            {/* Inv Shift 1 Akad & Resepsi */}
            {isInvitation && time ==="Akad-Resepsi" && (
              <>
                <WithAnimation>
                  <Box>
                    <WeddingDetail
                      color={color}
                      title="Akad Nikah"
                      titleProps={{ color }}
                      Icon={<IconWeddingRing height="100px" width="100px" color={color} />}
                      subTitle={
                        <>
                          <span>{WEDDING_AKAD_TIME}</span>
                          <br />
                          <span>{WEDDING_AKAD_FULLDATE}</span>
                        </>
                      }
                    />
                  </Box>
                </WithAnimation>
                <WithAnimation>
                  <WeddingDetail
                    color={color}
                    titleProps={{ color }}
                    title="Resepsi Pernikahan"
                    Icon={<FaGlassCheers size="80px" color={color} marginBottom="4px" />}
                    subTitle={
                      <>
                        <span>{WEDDING_RESEPSI_TIME}</span>
                        <br />
                        <span>{WEDDING_AKAD_FULLDATE}</span>
                      </>
                    }
                  />
                </WithAnimation>
                <Box>
                  <WithAnimation>
                    <Text
                      align="center"
                      fontSize="xl"
                      fontFamily="fantasy"
                      fontWeight="bold"
                      color="white"
                    >
                      <span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_NAME }} />
                    </Text>
                  </WithAnimation>
                  <WithAnimation>
                    <Text fontSize="sm" align="center" color={color}>
                      {WEDDING_RESEPSI_LOC_ROAD}
                    </Text>
                  </WithAnimation>
                  <WithAnimation>
                    <WeddingLocation
                      color={color}
                      withIcon={false}
                      withLocation={false}
                      withRoad={false}
                    />
                  </WithAnimation>
                </Box>
              </>
            )}
            
            {/* Inv Shift 2 Akad & Tasyakuran */}
            {isInvitation && time ==="Tasyakuran" && (
              <>
                <WithAnimation>
                  <Box>
                    <WeddingDetail
                      color={color}
                      title="Akad & Resepsi"
                      titleProps={{ color }}
                      Icon={<IconWeddingRing height="100px" width="100px" color={color} />}
                      subTitle={
                        <>
                          <span>
                            *) Akad dan Resepsi telah dilaksanakan pada tanggal 6 Januari 2024 di Balai Sasana Widya Praja, 
                            Jl. Setiabudi No. 201 A, Srondol Kulon, Banyumanik, Kota Semarang, Jawa Tengah
                          </span>
                        </>
                      }
                    />
                  </Box>
                </WithAnimation>
                <WithAnimation>
                  <WeddingDetail
                    color={color}
                    titleProps={{ color }}
                    title="Tasyakuran"
                    Icon={<FaGlassCheers size="80px" color={color} marginBottom="4px" />}
                    subTitle={
                      <>
                        <span>09.30 - 11.30 WITA</span>
                        <br />
                        <span>Sabtu, 13 Januari 2024</span>
                      </>
                    }
                  />
                </WithAnimation>
                <Box>
                  <WithAnimation>
                    <Text
                      align="center"
                      fontSize="xl"
                      fontFamily="fantasy"
                      fontWeight="bold"
                      color="white"
                    >
                      <span dangerouslySetInnerHTML={{ __html: `Kediaman Mempelai` }} />
                    </Text>
                  </WithAnimation>
                  <WithAnimation>
                    <Text fontSize="sm" align="center" color={color}>
                      Mahkota Sayang Sayang Blok B-17, Sayang-Sayang, Cakranegara, Kota Mataram, Nusa Tenggara Barat
                    </Text>
                  </WithAnimation>
                  <WithAnimation>
                    <WeddingLocation2
                      color={color}
                      withIcon={false}
                      withLocation={false}
                      withRoad={false}
                    />
                  </WithAnimation>
                </Box>
              </>
            )}
          </Box>

          {false &&
            <Box>
              <WithAnimation>
                <Center>
                  <Heading
                    fontSize="2xl"
                    color="white"
                    fontWeight="bold"
                    align="center"
                    fontFamily="fantasy"
                    marginTop="16px"
                  >
                    Informasi Acara
                  </Heading>
                </Center>
              </WithAnimation>
              <WithAnimation>
                {isInvitation ? (
                  <Text align="center" color="white" fontSize="xs" margin="8px 0">
                    *) Sehubungan dengan kondisi pandemi Covid-19, maka pelaksanaan resepsi akan
                    diberlangsungkan dengan sistem <b>hampers</b>. Sehingga tidak ada kegiatan{' '}
                    <i>fine dining</i>/ makan bersama-sama saat acara berlangsung. Mohon
                    pengertiannya, terima kasih.
                  </Text>
                ) : (
                  <Text align="center" fontSize="sm" color="white" margin="8px 0">
                    Mohon pemakluman kepada Bapak/Ibu/Saudara/i, kami hanya menyelanggarakan resepsi
                    secara terbatas dikarenakan adanya kondisi pandemi Covid-19 yang sedang terjadi.
                    Kami bermaksud membagikan kabar bahagia serta memohon doa restu agar putra-putri
                    kami menjadi keluarga sakinnah, mawaddah, warahmah.
                  </Text>
                )}
              </WithAnimation>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
}

WeddingSectionV4.propTypes = {
  color: string,
};

WeddingSectionV4.defaultProps = {
  color: 'white',
};

export default React.memo(WeddingSectionV4);