import React, { useState, useCallback } from 'react';

import CoverSection from '@components/Section/Cover';
import WelcomeSection from '@components/Section/Welcome';
import AyatSection from '@components/Section/Ayat';
import CoupleInfoSection from '@components/Section/CoupleInfo';
import WeddingSection from '@components/Section/Wedding';
import PhotoGallery from '@components/Section/PhotoGallery/Lazy';
import RSVPSection from '@components/Section/RSVP';
import CovidSection from '@components/Section/Covid';
import YoutubeLiveSection from '@components/Section/YoutubeLive';
import GiftCardSection from '@components/Section/GiftCard';
import FooterSection from '@components/Section/Footer';
import LoveStory from '@components/Section/LoveStory';
import WishesSection from '@components/Section/Wishes';

import Layout from '@components/Layout';
import FloatingMusic from '@components/Common/FloatingMusic/Lazy';
import WithAnimation from '@components/Common/WithAnimation';
import DarkmodeNotification from '@components/Common/DarkmodeNotification';
import QRCard from '@components/Common/QRCard/V2';

import useInvitation from '@/hooks/useInvitation';
import useGuestInfo from '@hooks/useGuestInfo';
import useLang from '@/hooks/useLang';

import Navigation from '@/components/Common/Navigation';
import * as FEATURE from '../constants/features-flag';
import * as ID from '../constants/identifier';
import useShowQR from '@/hooks/useShowQR';

const ELEMENT_CONTENT_ID = 'invitato-detail-content';

export default function Home() {
  const [showDetailContent, setShowDetailContent] = useState(false);
  const isInvitation = useInvitation();
  const isEligibleShowQR = useShowQR();
  const lang = useLang();
  const { code } = useGuestInfo();

  /**
   * function to show detail invitation
   * @returns {void}
   */
  const handleClickDetail = useCallback(() => {
    setShowDetailContent(true);
  }, []);

  /**
   * function to render invitation content
   * @returns {JSX.Element}
   */
  const renderContent = () => {
    if (!showDetailContent) return null;

    return (
      <div id={ELEMENT_CONTENT_ID}>
        <WelcomeSection />
        <AyatSection />
        <CoupleInfoSection name={ID.ID_COUPLE_SECTION} />
        {FEATURE.ENABLE_LOVE_STORY && <LoveStory />}
        <WeddingSection name={ID.ID_DETAIL_SECTION} />
        {FEATURE.ENABLE_QR_INVITATION && isInvitation && isEligibleShowQR && code && <QRCard lang={lang} name={ID.ID_QRCODE} />}
        {FEATURE.ENABLE_LIVE_STREAMING && <YoutubeLiveSection name={ID.ID_YOUTUBE_SECTION} />}
        {FEATURE.ENABLE_VIDEO_PREWEDDING && <YoutubeLiveSection name={ID.ID_YOUTUBE_SECTION} />}
        {FEATURE.ENABLE_GIFT_CARD && FEATURE.ENABLE_LIVE_STREAMING && <GiftCardSection name={ID.ID_GIFT_SECTION} lang={lang} />}
        {FEATURE.ENABLE_GIFT_CARD && FEATURE.ENABLE_VIDEO_PREWEDDING && <GiftCardSection name={ID.ID_GIFT_SECTION} lang={lang} />}
        <PhotoGallery name={ID.ID_GALLERY_SECTION} />
        {FEATURE.ENABLE_RSVP && isInvitation && <RSVPSection name={ID.ID_RSVP_SECTION} />}
        {FEATURE.ENABLE_COVID_PROTOCOL && isInvitation && <CovidSection name={ID.ID_COVID_SECTION} />}
        <WishesSection inverse lang={lang} />
        <FooterSection />
      </div>
    );
  };

  return (
    <Layout>
      <FloatingMusic shouldShowMusicIcon={showDetailContent} />
      {FEATURE.ENABLE_DARKMODE_NOTIFICATION && <DarkmodeNotification lang={lang} />}
      {showDetailContent && <Navigation />}
      <WithAnimation top collapse when={!showDetailContent}>
        <CoverSection elementTarget={ELEMENT_CONTENT_ID} onSeeDetail={handleClickDetail} />
      </WithAnimation>
      {renderContent()}
    </Layout>
  );
}
