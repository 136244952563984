import React from 'react';
import { Box, Heading, Center, Text, AspectRatio, Button, Link } from '@chakra-ui/react';

import Wave from '@components/Common/Wave';
import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE, YOUTUBE_EMBED, YOUTUBE_LINK } from '@/constants';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';
import { BG_YOUTUBE } from '@/constants/assets';
import txt from './locales';
import useLang from '@hooks/useLang';
import { ENABLE_VIDEO_PREWEDDING } from '@/constants/features-flag';

function YoutubeLiveSection({...rest}) {
  const lang = useLang();
  return (
    <Box bgColor="mainColor" {...rest}>
      <Box p={8}>
        <WithAnimation>
          <Center>
            <Heading color="white" fontSize="3xl" fontFamily="fantasy">
            {!ENABLE_VIDEO_PREWEDDING 
                ? txt.live[lang]
                : txt.prewed[lang]
              }
            </Heading>
          </Center>
          <Center>
            <Text fontSize="lg" color="white">
              {THE_BRIDE}
            </Text>
          </Center>
        </WithAnimation>
      </Box>
      <Wave
        v2
        isWhiteColor
        style={{ transform: 'translateY(40px)', backgroundColor: 'transparent' }}
      />
      <Box
        backgroundImage={`url(${BG_YOUTUBE})`}
        backgroundPosition="center"
        backgroundSize="cover"
        boxShadow={`inset 0 0 0 2000px ${BG_SECONDARY_TRANSPARENT}`}
        paddingBottom="32px"
      >
        <Box p={8} paddingBottom="0">
          <WithAnimation>
            <AspectRatio
              maxW="560px"
              ratio={16 / 9}
              borderRadius="lg"
              transform="translateY(-80px)"
              marginBottom={!ENABLE_VIDEO_PREWEDDING ? "" : "-32px"}
            >
              <iframe
                title={`Live wedding of ${THE_BRIDE}`}
                src={YOUTUBE_EMBED}
                allowFullScreen
                loading="lazy"
                style={{ borderRadius: '16px' }}
              />
            </AspectRatio>
          </WithAnimation>
        </Box>
        {!ENABLE_VIDEO_PREWEDDING && (
        <Text marginTop="-70px" color="white" align="center" fontSize="sm" padding="16px 32px">
          {txt.desc[lang]}
          <br />
          <Link href={YOUTUBE_LINK} target="_blank">
            <Button size="xs" className="animation-heart" colorScheme="orange" margin="8px">
              {txt.open[lang]}
            </Button>
          </Link>
        </Text>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);
