import React from 'react';
import { string } from 'prop-types';
import { Flex, Text } from '@chakra-ui/react';

import useDateCountdown from '@/hooks/useDateCountdown2';

import CountItem from './CountItem';

import { txtDay, txtHour, txtMinutes, txtSeconds } from './locales';
import useLang from '@hooks/useLang';


function CountContainer() {
  const { days, hours, minutes, seconds } = useDateCountdown();
  const lang = useLang();
  
  const renderBullet = () => {
    return (
      <Text fontWeight="bold" color="white" fontSize="20px">
        {'.'}
      </Text>
    );
  };

  return (
    <Flex alignItems="center">
      <CountItem text={txtDay[lang]} number={days} />
      {renderBullet()}
      <CountItem text={txtHour[lang]} number={hours} />
      {renderBullet()}
      <CountItem text={txtMinutes[lang]} number={minutes} />
      {renderBullet()}
      <CountItem text={txtSeconds[lang]} number={seconds} />
    </Flex>
  );
}

CountContainer.propTypes = {
  lang: string,
};

CountContainer.defaultProps = {
  lang: 'id',
};

export default React.memo(CountContainer);
