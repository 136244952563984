import React from 'react';

import { Box, Text, Divider, Center } from '@chakra-ui/react';
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';

import { BG_AYAT, BG_PATTERN } from '@/constants/assets';

/**
 * function to render ayat section with Ayat content
 * @returns {JSX.Element}
 */
function AyatSection() {
  return (
    <Box padding="42px 32px" bgImage={`url(${BG_PATTERN})`} bgSize="cover" bgPos="center">
      <Box p="24px 16px" bg="mainColor" borderRadius="16px">
        <Box marginBottom="16px">
          <Center>
            <WithAnimation>
              <Image src={BG_AYAT} borderTopRadius="48%" borderBottomRadius="8px" height="300px" />
            </WithAnimation>
          </Center>
        </Box>
        <Center margin="8px">
          <WithAnimation>
            <Divider orientation="vertical" height="42px" bg="white" width="1px" />
          </WithAnimation>
        </Center>
        <WithAnimation>
          <Text textAlign="center" fontSize="sm" color="white" fontStyle="italic">
            Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan untukmu dari jenismu sendiri, 
            agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. 
            Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text fontSize="sm" fontWeight="bold" align="center" color="white" marginTop="8px">
            (Q.S. Ar-Rum: 21)
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(AyatSection);
