import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { Box, Skeleton, Flex, SkeletonCircle, Text, Avatar } from '@chakra-ui/react';
import { AiFillPushpin } from 'react-icons/ai';

import getChackra from '@invitato/helpers/dist/getChackra';

const getName = (item) => {
  try {
    const name = `${item.nama}`.slice(0, 1);
    return name;
  } catch {
    return 'I';
  }
}

function Wishlist({ wishlistData, loading }) {
  const renderLoading = () => {
    const result = [];

    for (let i = 0; i < 2; i++) {
      result.push(
        <Flex alignItems="center" key={`loader-${i}`}>
          <Box marginRight="16px">
            <SkeletonCircle height="40px" width="40px" />
          </Box>
          <Box width="80%">
            <Box boxShadow="md" p={4} borderEndRadius="3xl" borderTopRadius="3xl">
              <Skeleton m="2" height="14px" width="50px" />
              <Skeleton m="2" height="14px" width="100%" />
              <Skeleton m="2" height="14px" width="200px" />
            </Box>
          </Box>
        </Flex>,
      );
    }

    return result;
  };

  const getWishItem = (item, index) => {
    return (
      <Flex alignItems="top" marginTop="16px" key={`wishlist-${index}`}>
        <Box marginRight="16px">
          <Avatar
            marginTop="4"
            name={getName(item)}
            backgroundColor="theme.bgSecondary"
            boxShadow="md"
            color="white"
            fontWeight="bold"
            borderWidth="3px"
            borderStyle="solid"
            height="40px"
            width="40px"
          />
        </Box>
        <Box width="80%">
          <Box
            borderWidth="1px"
            boxShadow="sm"
            bgColor="white"
            padding="4px 16px"
            borderTopEndRadius="2xl"
            borderBottomRadius="2xl"
          >
            <Flex alignItems="center">
              <Text fontWeight="bold" color="theme.bgSecondary" marginRight="8px" fontSize="sm">
                {item.nama}
              </Text>
              {item.pinByAdmin === 1 && <AiFillPushpin color={getChackra('bgSecondary')} />}
            </Flex>
            <Text fontSize="sm">{item.ucapan}</Text>
          </Box>
        </Box>
      </Flex>
    );
  };

  const renderWishlist = () => {
    let finalResult = [];
    let wishlist = [];
    let pinnedWishlist = [];

    wishlistData.forEach((item, index) => {
      if (item.pinByAdmin === 1) {
        pinnedWishlist.push(getWishItem(item, index));
      } else {
        wishlist.push(getWishItem(item, index));
      }
    });

    wishlist = wishlist.reverse();
    finalResult = [...pinnedWishlist, wishlist];

    return finalResult;
  };

  return (
    <Box height={wishlistData.length > 0 ? '300px' : '0'} overflow="scroll" margin="32px 0">
      <Box>{loading && renderLoading()}</Box>
      <Box>{!loading && renderWishlist()}</Box>
    </Box>
  );
}

Wishlist.propTypes = {
  loading: bool,
  wishlistData: arrayOf(object),
};

Wishlist.defaultProps = {
  loading: false,
  wishlistData: [],
};

export default Wishlist;
