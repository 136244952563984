import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { Box, Heading, Center, Image } from '@chakra-ui/react';

import ScrollToDown from '@/components/Common/ScrollToDown/V1';
import WithAnimation from '@/components/Common/WithAnimation';
import CopyRight from '@/components/Section/CopyRight';
import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';

import useInvitation from '@/hooks/useInvitation';
import IMG_LOGO from '@/assets/icons/jawa-2.png';

import { GIRL_NAME_SHORT, BOY_NAME_SHORT, IS_BOY_FIRST } from '@/constants';
import { BG_SECONDARY } from '@/constants/colors';
import { styCoverV4 } from './styles';
import txt from './locales';
import useLang from '@hooks/useLang';

/**
 * Function to render Component CoverV4
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function CoverV4({ onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const isInvitation = useInvitation();
  const lang = useLang();
  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setShowContent(true);
  }, [isInvitation]);

  return (
    <Box minHeight="100vh" width="100%" backgroundColor={BG_SECONDARY} pos="relative">
      <div css={styCoverV4}>
        <Box display="block" position="relative">
          {showContent && (
            <Box>
              <Center padding="0 0 8px 0" paddingTop="calc(50%)">
                <WithAnimation>
                  <Image src={IMG_LOGO} minHeight="70px" filter="invert(1)" maxWidth="70px" />
                </WithAnimation>
              </Center>
              {!isInvitation && (
                <Center>
                  <WithAnimation>
                    <Heading color="white" fontSize="2xl" marginBottom="12px">
                      {txt.announcement[lang]}
                    </Heading>
                  </WithAnimation>
                </Center>
              )}
              {isInvitation && (
                <Center>
                  <WithAnimation>
                    <Heading color="white" fontSize="5xl">
                      {txt.wedding[lang]}
                    </Heading>
                  </WithAnimation>
                </Center>
              )}
              <Center marginTop="-12px">
                <WithAnimation>
                  <Heading fontSize="xl" color="white">
                    {' '}
                    {IS_BOY_FIRST
                      ? `${BOY_NAME_SHORT} ${txt.and[lang]} ${GIRL_NAME_SHORT}`
                      : `${GIRL_NAME_SHORT} ${txt.and[lang]} ${BOY_NAME_SHORT}`}
                  </Heading>
                </WithAnimation>
              </Center>
            </Box>
          )}
        </Box>
      </div>
      <Box width="100%" pos="absolute" bottom="160px">
        <ScrollToDown onClick={handleClickDetail} loading={loading} />
      </Box>
      <CopyRight
        withSong={false}
        withFlatIcon={false}
        transformWave="translateY(-4rem)"
        transformBody="translateY(calc(-4rem - 2px))"
        fontSize="small"
      />
    </Box>
  );
}

CoverV4.propTypes = {
  onSeeDetail: func.isRequired,
};

export default CoverV4;
