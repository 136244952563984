import React, { useState } from 'react';
import { string } from 'prop-types';

import {
  Box,
  Text,
  Heading,
  Center,
  Button,
  // hooks
  useToast,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

import Wave from '@components/Common/Wave';
import WithAnimation from '@components/Common/WithAnimation';
import ModalConfirmation from './ModalConfirmation';
import ModalAddress from './ModalAddress';
import ModalBank from './ModalBank';

import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import {
  ENABLE_BANK_TRANSFER,
  ENABLE_GIFT_CONFIRMATION,
  ENABLE_SEND_GIFT,
} from '@/constants/features-flag';
import txtWording from './locales';

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  confirm: 'confirmation',
  idle: '',
};

function GiftCardSection({ lang, transformWave, transformBody, ...rest }) {
  const [modalState, setModalState] = useState(useState(MODAL_STATE.idle));
  const toast = useToast();

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: message || txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box {...rest}>
      <Wave style={{ backgroundColor: 'transparent', transform: transformWave }} />
      <Box padding="16px" bgColor="theme.bgSecondary" transform={transformBody}>
        <Box>
          <Center>
            <WithAnimation>
              <Heading size="xl" color="white" fontFamily="fantasy">
                {txtWording.title[lang]}
              </Heading>
            </WithAnimation>
          </Center>
          <WithAnimation>
            <Text color="whiteAlpha.900" align="center" marginTop="12px" fontSize="sm">
              {txtWording.desc[lang]}
            </Text>
          </WithAnimation>
          {ENABLE_BANK_TRANSFER && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  fontWeight="normal"
                  onClick={() => setModalState(MODAL_STATE.bank)}
                >
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_SEND_GIFT && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  fontWeight="normal"
                  onClick={() => setModalState(MODAL_STATE.address)}
                >
                  {txtWording.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_GIFT_CONFIRMATION && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  className="animation-heart"
                  colorScheme="blackAlpha"
                  leftIcon={<FaCheckCircle style={{ marginRight: '-4px' }} />}
                  fontWeight="normal"
                  onClick={() => setModalState(MODAL_STATE.confirm)}
                >
                  {txtWording.confirm[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
        </Box>
      </Box>
      <Box marginTop="-40px" height="20px" bgColor="theme.bgSecondary" />
      <ModalBank
        lang={lang}
        visible={modalState === MODAL_STATE.bank}
        onClose={onClose}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        visible={modalState === MODAL_STATE.address}
        onClose={onClose}
        onCopyText={onCopyText}
      />
      <ModalConfirmation
        lang={lang}
        visible={modalState === MODAL_STATE.confirm}
        onClose={onClose}
      />
    </Box>
  );
}

GiftCardSection.propTypes = {
  transformBody: string,
  transformWave: string,
  lang: string,
};

GiftCardSection.defaultProps = {
  transformBody: 'translateY(-40px)',
  transformWave: 'translateY(-38px)',
  lang: 'id',
};

export default React.memo(GiftCardSection);
