import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
} from '@chakra-ui/react';

import Confirmation from '../Confirmation';

function ModalConfirmation({ lang, visible, onClose }) {
  return (
    <Drawer size="full" isOpen={visible} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" />
        <DrawerBody padding="0">
          <Confirmation onBack={onClose} lang={lang} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalConfirmation.propTypes = {
  lang: string.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

export default ModalConfirmation;
