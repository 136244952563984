import { css } from '@emotion/react';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';
import { BG_WELCOME } from '@/constants/assets';

export const styWelcomeV3 = css`
  min-height: 75vh;
  background-size: cover;
  background-image: url(${BG_WELCOME});
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 2000px ${BG_SECONDARY_TRANSPARENT};
`;
