import get from '@invitato/helpers/dist/getLocale';

export default {
  announcement: get('Pengumuman Pernikahan','Wedding Announcement'),
  openingMuslimInvitation: get(`Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
  `Together with the blessing of Allah Subhanahu wa Ta'ala, we cordially request the honor of your presence at the wedding celebration of:`),
  
  openingMuslimAnnouncement: get(`Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
  `Together with the blessing of Allah Subhanahu wa Ta'ala, we joyfully announce the upcoming marriage of:`),
  
  openingGeneralInvitation: get(`Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
  `Together with joyful hearts and the grace of God, we cordially request the honor of your presence at the wedding celebration of:`),

  openingGeneralAnnouncement: get(`Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
  `Together with joyful hearts and the grace of God, we joyfully announce the upcoming marriage of:`),
};