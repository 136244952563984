import React from 'react';
import { Box, Heading, Center, Text } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Wave from '@/components/Common/Wave';

import useGuestName from '@/hooks/useGuestName';
import useInvitation from '@/hooks/useInvitation';

import { TEXT_SECONDARY, BG_SECONDARY } from '@/constants/colors';
import { styWelcomeV3 } from './styles';
import txt from './locales';
import useLang from '@hooks/useLang';
import { ENABLE_MUSLIM_INVITATION } from '@/constants/features-flag';
/**
 * Function to render Component WelcomeV3
 * @return {JSX.Element}
 */
function WelcomeV3() {
  const isInvitation = useInvitation();
  const name = useGuestName();
  const lang = useLang();

  return (
    <Box minHeight="100vh" width="100%" backgroundColor={BG_SECONDARY}>
      <Box backgroundColor={BG_SECONDARY}>
        <Box css={styWelcomeV3}>
          <Box display="block">
            <Box paddingTop="calc(80%)">
              <WithAnimation>
                <Text
                  color={TEXT_SECONDARY}
                  fontSize="20px"
                  fontFamily="fantasy"
                  align="center"
                  marginTop="8px"
                >
                  {txt.dear[lang]}
                </Text>
              </WithAnimation>
              <Box padding="0 16px">
                <Center>
                  <WithAnimation>
                    <Text color="white" align="center" fontFamily="body" marginBottom="40px">
                      {name || txt.to[lang]}
                    </Text>
                  </WithAnimation>
                </Center>
              </Box>
            </Box>
          </Box>
        </Box>
        <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-2.5rem)' }} />
        <Box transform="translateY(-2rem)" padding="16px 16px 0 16px">
          <Center>
            {ENABLE_MUSLIM_INVITATION ? (
              <WithAnimation>
                <Heading textAlign="center" color="white" marginBottom="2" fontSize="2xl">
                  <span>{`Assalamu'alaikum`}</span> <br />
                  <span>Warahmatullahi Wabarakaatuh</span>
                </Heading>
              </WithAnimation>
            ) : (
              <WithAnimation>
                <Heading textAlign="center" color="white" marginBottom="2" fontSize="2xl">
                  <span>{txt.greeting[lang]}</span>
                </Heading>
              </WithAnimation>
            )}
          </Center>
          <WithAnimation>
            <Text textAlign="center" color="white" fontSize="sm">
              {isInvitation ? <>{txt.invitation[lang]}</> : <>{txt.announcement[lang]}</>}
            </Text>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WelcomeV3);
