import React from 'react';
import { bool, string } from 'prop-types';
import { Box, Center, Text, Link, Divider, Heading } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import useInvitation from '@/hooks/useInvitation';
import Invitato from '@/images/invitato.png';
import GiftCard from '@components/Section/GiftCard';
import Wave from '@components/Common/Wave';

import { ENABLE_COLLABORATION, ENABLE_GIFT_CARD, ENABLE_LIVE_STREAMING, ENABLE_MUSLIM_INVITATION, ENABLE_VIDEO_PREWEDDING } from '@/constants/features-flag';
import { THE_BRIDE, SOUND_BY, SOUND_URL, URL_IG_INVITATO } from '@/constants';
import txt from './locales';
import useLang from '@hooks/useLang';
import * as ID from '@/constants/identifier';
import { LOGO_VENDOR_PENTONE } from '@/constants/assets';
import { BG_SECONDARY_DARK } from '@/constants/colors';

function CopyRightSection({ withSong, withFlatIcon, fontSize }) {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box>
      <Box bgColor="theme.bgSecondary">
      {ENABLE_LIVE_STREAMING && (
          <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-38px)' }} />
        )}
        {ENABLE_VIDEO_PREWEDDING && (
          <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-38px)' }} />
        )}
        {!ENABLE_GIFT_CARD && (
          <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-38px)' }} />
        )}
        {!ENABLE_LIVE_STREAMING && !ENABLE_VIDEO_PREWEDDING && ENABLE_GIFT_CARD && (
          <>
            <GiftCard lang={lang} name={ID.ID_GIFT_SECTION} />
            <Center>
              <Divider
                margin="-2px 0 12px"
                maxWidth="70%"
                height="1px"
                bgColor="whiteAlpha.900"
                color="whiteAlpha.900"
                border="none"
                boxShadow="none"
                borderBottomWidth="none"
              />
            </Center>
          </>
        )}
        <Box p={4}>
          <WithAnimation>
            <Text align="center" color="whiteAlpha.900" fontSize="sm" marginTop={!ENABLE_LIVE_STREAMING && !ENABLE_VIDEO_PREWEDDING && ENABLE_GIFT_CARD ? "" :"-24px"}>
              {isInvitation
                ? <>{txt.closingInvitation[lang]}</>
                : <>{txt.closingAnnouncement[lang]}</>
              }
            </Text>
          </WithAnimation>
          <WithAnimation>
          {ENABLE_MUSLIM_INVITATION &&
            <Text align="center" color="whiteAlpha.900" fontSize="sm">
              Wassalamualaikum Warahmatullahi Wabarakaatuh
            </Text>}
          </WithAnimation>
          <Center>
            <WithAnimation>
              <Heading fontSize="3xl" color="white" margin="24px 0">
                {THE_BRIDE}
              </Heading>
            </WithAnimation>
          </Center>
          <Center>
            <WithAnimation>
              <Text fontSize="lg" color="white" margin="-16px 0 18px">
                #VIANallyWINs
              </Text>
            </WithAnimation>
          </Center>
        </Box>
      </Box>
      <Box padding="24px 16px" bgColor={BG_SECONDARY_DARK}>
        <Center>
          <Link href={URL_IG_INVITATO} target="_blank">
            <Image src={Invitato} maxWidth="110px" margin="0" />
          </Link>
        </Center>
        <Text textAlign="center" color="white" fontSize="xs" margin="-4px 0 12px 0">
          Created with love by Invitato
        </Text>
        {ENABLE_COLLABORATION &&
            <Box margin="-4px 0 18px">
              <Center>
                <Divider
                  margin="8px 0 16px"
                  maxWidth="130px"
                  opacity="1"
                  height="1px"
                  bgColor="mainColorText"
                  border="none"
                />
              </Center>
              <Center>
                <Link href="https://www.instagram.com/pentone.id/" target="_blank">
                  <Image 
                    src={LOGO_VENDOR_PENTONE}
                    maxWidth="75px"
                  />
                </Link>
              </Center>
              <Center>
                <Text color="white" align="center" fontSize="xs">
                  In Collaboration with Pentone
                </Text>
              </Center>
            </Box>
            }
        <Center>
          <Text color="white" align="center" fontSize={fontSize}>
            {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
          </Text>
        </Center>
        {withSong && (
          <Center>
            <Link href={SOUND_URL} textAlign="center" color="white" fontSize={fontSize} isExternal>
              {`Song by ${SOUND_BY}`}
            </Link>
          </Center>
        )}
        {withFlatIcon && (
          <Center>
            <Link href="https://www.flaticon.com/" textAlign="center" color="white" fontSize={fontSize} isExternal>
              {`Icon by FlatIcon`}
            </Link>
          </Center>
        )}
        <Center m="1" />
      </Box>
    </Box>
  );
}

CopyRightSection.propTypes = {
  fontSize: string,
  withFlatIcon: bool,
  withSong: bool,
};

CopyRightSection.defaultProps = {
  fontSize: 'sm',
  withFlatIcon: true,
  withSong: true,
};

export default React.memo(CopyRightSection);
