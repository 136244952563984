import React from 'react';
import { string, bool } from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Box, Center, Text, Button, Stack } from '@chakra-ui/react';
import { BiBuildingHouse } from 'react-icons/bi';

import {
  THE_BRIDE,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_LOC_NAME,
} from '@/constants';
import { TEXT_PRIMARY_LIGHT } from '@/constants/colors';
import txt from './locales';
import useLang from '@hooks/useLang';

/**
 * extend UTC Time format
 */
dayjs.extend(utc);

function WeddingLocation({ color, withIcon, withLocation, withRoad }) {
  const handleClickGoogleMaps = () => {
    window.open(`https://maps.app.goo.gl/y96TrsMqjsV3QnLH7`, '_blank');
  };
  const lang = useLang();
  /**
   * Generate link for Google Calendar
   * @returns {void}
   */
  const handleClickAddToCalendar = () => {
    const title = `Wedding of ${THE_BRIDE} by Invitato.net`;
    const finalURL = `text=${encodeURIComponent(title)}`;
    const location = `location=${`Mahkota Sayang Sayang, Sayang Sayang, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Barat`}`;
    

    const startDate = dayjs(1705109400 * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');
    const endDate = dayjs(1705116600 * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');

    window.open(
      `https://www.google.com/calendar/render?action=TEMPLATE&${finalURL}&dates=${startDate}/${endDate}&${location}`,
      '_blank',
    );
  };

  return (
    <Box>
      {withIcon && (
        <Center>
          <BiBuildingHouse size="4rem" color={color} />
        </Center>
      )}
      {withLocation && (
        <Center>
          <Text fontSize="xl" fontWeight="bold" color={TEXT_PRIMARY_LIGHT}>
            {WEDDING_RESEPSI_LOC_NAME}
          </Text>
        </Center>
      )}
      {withRoad && (
        <Center>
          <Text fontSize="md" align="center" color={color}>
            {WEDDING_RESEPSI_LOC_ROAD}
          </Text>
        </Center>
      )}
      <Center m="5">
        <Stack direction="row" spacing={4} align="center">
          <Button size="sm" onClick={handleClickAddToCalendar} colorScheme="whiteAlpha" className="animation-heart">
            {txt.reminder[lang]}
          </Button>
          <Button size="sm" onClick={handleClickGoogleMaps} colorScheme="whiteAlpha" className="animation-heart">
            {txt.location[lang]}
          </Button>
        </Stack>
      </Center>
    </Box>
  );
}

WeddingLocation.propTypes = {
  color: string,
  withIcon: bool,
  withLocation: bool,
  withRoad: bool,
};

WeddingLocation.defaultProps = {
  color: 'black',
  withIcon: true,
  withLocation: true,
  withRoad: true,
};

export default WeddingLocation;
