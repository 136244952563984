import React from 'react';
import { string, object } from 'prop-types';
import { Box, Center, Heading, Text } from '@chakra-ui/react';
import Image from '@/components/Common/LazyImage';

import InstagramButton from '@components/Common/InstagramButton';

/**
 * Function to render CoupleDetailCard Info
 * @return {JSX.Element}
 */
function CoupleDetailCard(props) {
  const {
    name,
    fullName,
    parentInfo,
    instagramId,
    imgSrc,
    fullNameProps,
    parentInfoProps,
    instagramIdProps,
  } = props;
  return (
    <Box>
      <Center>{imgSrc && <Image src={imgSrc} maxWidth="120px" borderRadius="8px" />}</Center>
      <Center>
        <Heading fontSize="5xl" color="#d09d2d">
          {name}
        </Heading>
      </Center>
      <Center>
        <Text
          align="center"
          fontSize="1.2rem"
          fontWeight="bold"
          color="white"
          fontFamily="fantasy"
          {...fullNameProps}
        >
          {fullName}
        </Text>
      </Center>
      <Center>
        <Text textAlign="center" fontSize="sm" color="white" {...parentInfoProps}>
          <span color="white" dangerouslySetInnerHTML={{ __html: parentInfo }} />
        </Text>
      </Center>
      {instagramId && (
        <Center p={2}>
          <InstagramButton id={instagramId} {...instagramIdProps} />
        </Center>
      )}
    </Box>
  );
}

CoupleDetailCard.propTypes = {
  fullName: string.isRequired,
  fullNameProps: object,
  imgSrc: string,
  instagramId: string,
  instagramIdProps: object,
  name: string.isRequired,
  parentInfo: string.isRequired,
  parentInfoProps: object,
};

CoupleDetailCard.defaultProps = {
  instagramId: '',
  imgSrc: '',
  instagramIdProps: {},
  fullNameProps: {},
  parentInfoProps: {},
};

export default CoupleDetailCard;
