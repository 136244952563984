import Img1 from '@/images/gallery/1.jpg';
import Img2 from '@/images/gallery/1.jpg';
import Img3 from '@/images/gallery/1.jpg';
import Img4 from '@/images/gallery/1.jpg';
import Img5 from '@/images/gallery/1.jpg';
import Img6 from '@/images/gallery/1.jpg';
import Img7 from '@/images/gallery/1.jpg';
import Img8 from '@/images/gallery/1.jpg';

export default [
  {
    img: Img1,
    title: '03-03-2018',
    description: `Lorem ipsum dolor sit amet`,
  },
  {
    img: Img2,
    title: '03-06-2019',
    description: `consectetur adipisicing elit`,
  },
  {
    img: Img3,
    title: '26-12-2019',
    description: `sed do eiusmod tempor incididunt ut labore et dolore magna aliqua`,
  },
  {
    img: Img4,
    title: '22-11-2020',
    description: `Ut enim ad minim veniam`,
  },
  {
    img: Img5,
    title: '20-12-2020',
    description: `quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat`,
  },
  {
    img: Img6,
    title: `23-04-2021`,
    description: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
  },
  {
    img: Img7,
    title: `21-11-2021`,
    description: `Excepteur sint occaecat cupidatat non proident`,
  },
  {
    img: Img8,
    title: `19-12-2021`,
    description: `sunt in culpa qui officia deserunt mollit anim id est.`,
  },
];
