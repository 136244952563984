import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear Mr/Mrs/Ms,'),
  to: get('Keluarga & Teman-teman','Family & Friends'),
  
  greeting: get('Salam Sejahtera', 'Greetings,'),

  invitation: get(
    'Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Besar harapannya, Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam hari pernikahan kami.',
    'We are pleased to announce and invite you to our wedding. We sincerely hope that you will be able to attend and pray directly on our wedding day. Your presence will mean a lot to us.'),
  
  announcement: get(
    'Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Tanpa mengurangi rasa hormat, kami memohon doa restu untuk keberlangsungan acara pernikahan kami.',
    'Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Tanpa mengurangi rasa hormat, mengingat masih dalam masa pandemi, kami mohon maaf tidak bisa mengundang Bapak/Ibu/Saudara/i secara langsung dalam acara pernikahan kami. Tetapi kami tetap memohon doa restu untuk keberlangsungan acara pernikahan kami.'),
  
};