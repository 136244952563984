import React from 'react';
import { Box, Heading, Text, Center, useDisclosure } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';

import Img from 'gatsby-image';
import ImagesViewer from '@components/Common/ImagesViewer/Lazy';
import WithAnimation from '@components/Common/WithAnimation';


import { BG_PRIMARY, TEXT_PRIMARY_LIGHT, BG_SECONDARY_LIGHT } from '@/constants/colors';
import { DEFAULT_LANGUAGE as lang } from '@/constants/features-flag';

function CovidSection({...rest}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const data = useStaticQuery(graphql`
    query {
      covid: file(relativePath: { eq: "covid.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      covidEn: file(relativePath: { eq: "covid-en.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box {...rest}>
      <Box p={4} paddingTop="32px" paddingBottom="0" bgColor={BG_PRIMARY}>
        {false && <WithAnimation>
          <Center>
            <Heading color={TEXT_PRIMARY_LIGHT} fontFamily="fantasy">
              Protokol Kesehatan
            </Heading>
          </Center>
        </WithAnimation>}
        {false&& <WithAnimation>
          <Center>
            <Text textAlign="center" marginTop="2" color="mainColorText" fontSize="sm">
              Demi kenyamanan bersama, <br />
              acara pernikahan kami memberlakukan protokol kesehatan berikut ini:
            </Text>
          </Center>
        </WithAnimation>}
        <Box height="100px" />
      </Box>
      <Box padding="16px 32px" bgColor="mainColor" marginBottom="-80px">
        <WithAnimation>
          <Box
            boxShadow="lg"
            borderRadius="40px"
            onClick={onOpen}
            backgroundColor={BG_SECONDARY_LIGHT}
            border={`6px solid ${BG_SECONDARY_LIGHT}`}
            transform="translateY(-100px)"
          >
            <Img
              fluid={lang === 'en' ? data.covidEn.childImageSharp.fluid : data.covid.childImageSharp.fluid}
              imgStyle={{ borderRadius: '32px' }}
              style={{ backgroundColor: 'transparent' }}
            />
          </Box>
        </WithAnimation>
      </Box>
      {isOpen && (
        <ImagesViewer 
          images={[
            lang === 'en' 
              ? data.covidEn.childImageSharp.fluid.src
              : data.covid.childImageSharp.fluid.src
          ]}onClose={onClose}
        />
      )}
    </Box>
  );
}

export default React.memo(CovidSection);
