import React, { useState, useEffect, useRef } from 'react';
import { bool, string } from 'prop-types';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Spacer,
  Text,
  Textarea,
  CloseButton,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Wishlist from './Wishlist';
import { API_HOSTNAME, API_TABLE_NAME } from '@/constants';
import { BG_WISHLIST } from '@/constants/assets';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';
import Wave from '@components/Common/Wave';
import txtWording from './locales';

const LOADING = {
  GET_DATA: 'GET_DATA',
  SUBMIT_DATA: 'SUMIT_DATA',
  IDLE: 'IDLE',
};

const ALERT = {
  success: false,
  error: false,
  messageTitle: '',
  messageContent: '',
};

const ERROR_TYPE = {
  name: undefined,
  ucapan: undefined,
};

function WishesSection({ inverse,transformWave,lang }) {
  const [loadingType, setLoadingType] = useState(LOADING.IDLE);
  const [wishlist, setWishlist] = useState([]);
  const [showAlert, setShowAlert] = useState(ALERT);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const [name, setName] = useState('');
  const [ucapan, setUcapan] = useState('');
  const calledOne = useRef(false);

  const handleSetState = (e, setState) => {
    const value = e.target.value;
    setState(value);
  };

  const finishLoading = () => {
    setLoadingType(LOADING.IDLE);
  };

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      messageTitle = 'Oops!';
      messageContent = txtWording.failedMessage[lang];
    }

    setShowAlert({ ...ALERT, messageTitle, messageContent, error: !isSuccess, success: isSuccess });
  };

  /**
   * function to get wishlist data
   * @return {void}
   */
  const getData = async () => {
    setLoadingType(LOADING.GET_DATA);

    try {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'GET',
      };

      const rawResult = await fetch(
        `${API_HOSTNAME}?action=read&tableName=${API_TABLE_NAME}`,
        options,
      );
      const response = await rawResult.json();

      if (response.success) {
        setWishlist(response.data || []);
      } else {
        console.error('ERR_WHEN_GET_DATA', 200);
      }
      finishLoading();
    } catch (e) {
      finishLoading();
      console.error('ERR_WHEN_CALL_DATA', 500);
    }

    calledOne.current = true;
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

      // validate input data
      if (!name || !ucapan) {
        setErrorType({
          name: !name && txtWording.requiredField[lang],
          ucapan: !ucapan && txtWording.requiredField[lang],
        });
        return;
      }

    setLoadingType(LOADING.SUBMIT_DATA);

    try {
      const config = `tableName=${API_TABLE_NAME}&action=insert_wish`;
      const rawResult = await fetch(
        `${API_HOSTNAME}?${config}&nama=${encodeURIComponent(name)}&wish=${encodeURIComponent(
          ucapan,
        )}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          method: 'POST',
        },
      );

      const response = await rawResult.json();
      if (response.success) {
        setName('');
        setUcapan('');
        getData();
        handleSetAlert(true);
      } else {
        handleSetAlert(false);
      }
    } catch (e) {
      handleSetAlert(false);
    }

    finishLoading();
  };

  /** Side effect to autoscroll */
  useEffect(() => {
    if (!calledOne.current) {
      getData();
    }
  }, []);

  const renderAlert = () => {
    if (!showAlert.messageTitle) return null;

    return (
      <Box paddingBottom="0" paddingTop="16px">
        <Alert status={showAlert.success ? 'success' : 'error'} borderRadius="lg">
          <AlertIcon />
          <AlertDescription fontSize="sm">{showAlert.messageContent}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            size="sm"
            onClick={() => setShowAlert(ALERT)}
          />
        </Alert>
      </Box>
    );
  };

  return (   
    <Box
      backgroundImage={`url(${BG_WISHLIST})`}
      backgroundPosition="center"
      backgroundSize="cover"
      boxShadow={`inset 0 0 0 2000px ${BG_SECONDARY_TRANSPARENT}`}
    >
      <Box padding="16px">
        <Box>
          <WithAnimation>
            <Center paddingTop="24px">
              <Heading textAlign="center" color={inverse ? 'white' : 'theme.bgSecondary'} fontFamily="fantasy">
                {txtWording.title[lang]}
              </Heading>
            </Center>
            <Center m={2}>
              <Text align="center" color={inverse ? 'white' : 'black'}>
                <span dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }}/>
              </Text>
            </Center>
          </WithAnimation>
        </Box>
        {renderAlert()}
        {/* Box for FORM */}

        <WithAnimation>
          <Box paddingTop="2">
            <FormControl margin="4px 0" isInvalid={errorType.name}>
              <Input
                focusBorderColor="theme.bgSecondary"
                isRequired
                colorScheme="orange"
                type="text"
                size="md"
                borderRadius="md"
                variant="filled"
                placeholder={txtWording.name[lang]}
                onChange={(e) => handleSetState(e, setName)}
                value={name}
                style={{ backgroundColor: 'white' }}
                _focus={{ bgColor: 'white' }}
              />
              <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
            </FormControl>
            <FormControl margin="8px 0" isInvalid={errorType.ucapan}>
              <Textarea
                type="text"
                focusBorderColor="theme.bgSecondary"
                size="md"
                variant="filled"
                borderRadius="md"
                placeholder={txtWording.wish[lang]}
                onChange={(e) => handleSetState(e, setUcapan)}
                value={ucapan}
                style={{ backgroundColor: 'white' }}
                _focus={{ bgColor: 'white' }}
              />
               <FormErrorMessage marginTop="4px">{errorType.ucapan}</FormErrorMessage>
            </FormControl>
            <Flex justifyItems="end">
              <Spacer />
              <Box>
                <Button
                  isLoading={loadingType === LOADING.SUBMIT_DATA}
                  size="sm"
                  padding="8px 24px"
                  colorScheme="button.secondary"
                  onClick={handleSubmit}
                >
                  {txtWording.send[lang]}
                </Button>
              </Box>
            </Flex>
          </Box>
        </WithAnimation>

        <WithAnimation>
          <Wishlist wishlistData={wishlist} loading={loadingType === LOADING.GET_DATA} />
        </WithAnimation>
      </Box>
      <Wave style={{ backgroundColor: 'transparent', transform: transformWave }} />
    </Box>

  );
}

WishesSection.propTypes = {
  inverse: bool,
  lang: string,
  transformWave: string,
};

WishesSection.defaultProps = {
  inverse: false,
  lang: 'id',
};

export default React.memo(WishesSection);
