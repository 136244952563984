import React from 'react';
import { Box, Text, Button, Center, useDisclosure, Image } from '@chakra-ui/react';

// import ImgLogo from '@/assets/icons/jawa.png';

import WithAnimation from '@/components/Common/WithAnimation';
import Content from './Content';

import { styles } from './styles';
import { INVITATO_JAWA_LOGO } from '@/constants/assets';
import { BG_LOVE_JOURNEY } from '@/constants/assets';
import txt from './locales';
import useLang from '@hooks/useLang';

function LoveStory() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const lang = useLang();
  return (
    <Box bgColor="bgPrimary">
      {/* Heading */}
      <WithAnimation></WithAnimation>
      {/* Image Cover */}
      <Box padding="16px 32px" paddingBottom="42px">
        <Box css={styles}>
          <WithAnimation>
            <Image src={BG_LOVE_JOURNEY} width="100%" boxShadow="2xl" />
          </WithAnimation>
        </Box>
        <WithAnimation>
          <Box padding="0 8px">
            <Box bgColor="mainColor" padding="16px" borderRadius="0 0 24px 24px" marginTop="-24px">
              <Box height="24px" />
              <Center>
                <Image src={INVITATO_JAWA_LOGO} width="100px" />
              </Center>

              <WithAnimation>
                <Text padding="8px" color="white" textAlign="center">
                  {txt.desc[lang]}
                </Text>
              </WithAnimation>
              <WithAnimation>
                <Center margin="16px 0">
                  <Button
                    colorScheme="whiteAlpha"
                    fontFamily="body"
                    size="sm"
                    fontWeight="normal"
                    onClick={() => onOpen()}
                  >
                    {txt.textButton[lang]}
                  </Button>
                </Center>
              </WithAnimation>
            </Box>
          </Box>
        </WithAnimation>
      </Box>

      <Content onClose={onClose} isOpen={isOpen} />
    </Box>
  );
}

export default LoveStory;
