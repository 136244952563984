import get from '@invitato/helpers/dist/getLocale';

export default {
    textButton: get('Kisah Cinta Kami', 'Our Love Story'),
    desc: get(
        `Baca dan ikuti perjalanan cinta kami sebagai pasangan di sini:`,
        `Read and follow our love of live journey as a couple here:`,
    ),
    backAnn: get('Kembali', 'Back'),
    back: get('Kembali ke undangan', 'Back to Invitation'),
};